/*
 * @Author: liuhanchuan 
 * @Date: 2021-12-20 17:37:16 
 * @Last Modified by: liuhanchuan
 * @Last Modified time: 2021-12-21 09:32:28
 */
<template>
  <div class="test_container">
    <div class="upload_samll delete">
      <div style="width: 188px; height: 188px">
          <img :src="uploadUrl ? uploadUrl : '../../../assets/images/image_add.svg'" alt="">
          <span v-show="uploadUrl">
              <i class="el-icon-delete" @click="deleteImg('wxQrCode')"></i>
          </span>
      </div>
      <span @click="importWord">重新上传</span>
      <input type="file" ref="fileInput" style="display: none" @change="batchImport" accept=".jpeg,.jpg,.png,.gif">
    </div>
    <CropperDialog ref="cropper" @receiveData="setUploadUrl" />
  </div>
</template>

<script>
import CropperDialog from './cropperDialog/index.vue'
export default {
  data: function () {
    return {
      uploadUrl: ''
    };
  },
  components: {
    CropperDialog
  },
  methods: {
    deleteImg() {
      this.uploadUrl = ''
    },
    importWord() {
      this.$refs.fileInput.click()
    },
    //图片上传前，检查大小是否超出
    imgBeforeUpload(file) {
        const isFileSizeFit = file.size / 1024 / 1024 < 5;
        if (!isFileSizeFit) {
            this.$message.error("图片大小不能超过 5MB!");
        }

        return isFileSizeFit;
    },
    batchImport(e) {
        const [file] = e.target.files;
        const checkSize = this.imgBeforeUpload(file)
        if (checkSize) {
            this.imgUploadimg(file)
        }
          // 清空input的值
        this.$refs.fileInput.value = ''
    },
    // 上传logo图片
    imgUploadimg(file) {
        this.$refs.cropper.openDialog(file)
    },
    setUploadUrl(url) {
      this.uploadUrl = url
    }
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.upload_samll {
    display: flex;
    align-items: flex-end;
    > div {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f8ff;
        border: 1px solid #f5f8ff;
        box-sizing: content-box;
        height: 64px;
        width: 64px;
        > img {
            max-width: 100%;
            max-height: 100%;
        }
        > span {
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(29, 29, 29, 0.7);
            opacity: 0;
            color: #fff;
            cursor: pointer;
        }
    }
    > span {
        margin-left: 16px;
        font-size: 12px;
        color: #3472E8;
        cursor: pointer;
        &:hover {
            color: #1a61e9;
        }
    }
}
.delete:hover {
    > div {
        > span {
            opacity: 1;
        }
    }
}
</style>
