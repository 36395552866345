import axios from "axios";
import API from "./api";

// 搜索股票
export function getStock(params) {
  return new Promise(async (resolve) => {
    let { data } = await axios({
      url: `https://sec.wedengta.com/search?action=search`,
      method: "get",
      params,
    });
    try {
      resolve(JSON.parse(data.content))
    } catch (error) {
      resolve({})
    }
  })
}
// export function uploadVideoByInterface(params) {
//   return new Promise(async (resolve) => {
//     let { data } = await axios({
//       url: `/yrf/master_material/ossFileUpload/uploadVideoByInterface`,
//       method: "post",
//       data: params,
//     });
//     try {
//       resolve(data)
//     } catch (error) {
//       resolve({})
//     }
//   })
// }
export function uploadFile (file, progress, suffix) {
  return new Promise(async(resolve) => {
    const token = localStorage.getItem("Authorization");
    let formData = new FormData();
    formData.append('file', file)
    let { data } = await axios({
      url: API.uploadFile.upload,
      method: "post",
      data: formData,
      onUploadProgress: progress,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token
      },
      timeout: 30 * 60 * 1000
    });
    if (data.code == 200) {
      resolve(data)
    } else {
      resolve()
    }
  })
}

export function uploadVideo (file, progress, suffix) {
  return new Promise(async(resolve) => {
    const token = localStorage.getItem("Authorization");
    let formData = new FormData();
    formData.append('file', file)
    let { data } = await axios({
      url: API.uploadFile.uploadVideo,
      method: "post",
      data: formData,
      onUploadProgress: progress,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token
      },
      timeout: 30 * 60 * 1000
    });
    resolve(data)
  })
}